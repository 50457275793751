<template>
  <div>
    <b-card class="mb-5" no-body>
      <b-card-header class="p-1" header-tag="header" role="tab">
        <b-button v-b-toggle.accordion-2 block class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                  variant="link">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('aged_debtors.customer') }}</label>
                <multiselect v-model="customer" :multiple="false"
                             :options="customers"
                             :placeholder="$t('aged_debtors.customer')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.branches') }}</label>
                <multiselect v-model="branches_ids"
                             :multiple="true"
                             :options="branches"
                             :placeholder="$t('customer_transactions_report.branches')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id"
                             @input="getIds('branches')">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="from_date">{{ $t('customer_transactions_report.from_date') }}</label>
                <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="to_date">{{ $t('customer_transactions_report.to_date') }}</label>
                <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
              </div>
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button :variant="filters.period=='current_day'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('current_day')">
                      {{ $t('customer_transactions_report.current_date') }}
                    </b-button>
                    <b-button :variant="filters.period=='current_month'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('current_month')">
                      {{ $t('customer_transactions_report.current_month') }}
                    </b-button>
                    <b-button :variant="filters.period=='current_year'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('current_year')">
                      {{ $t('customer_transactions_report.current_year') }}
                    </b-button>
                    <b-button :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('financial_year')">
                      {{ $t('customer_transactions_report.financial_year') }}
                    </b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>
                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('yesterday')">
                      {{ $t('customer_transactions_report.yesterday') }}
                    </b-button>
                    <b-button :variant="filters.period=='last_month'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('last_month')">
                      {{ $t('customer_transactions_report.last_month') }}
                    </b-button>
                    <b-button :variant="filters.period=='last_year'? 'primary' : 'outline-primary'"
                              @click="changeIntervalType('last_year')">
                      {{ $t('customer_transactions_report.last_year') }}
                    </b-button>
                  </b-button-group>
                </div>
              </div>

              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                  {{ $t('display_report') }}
                </button>
                <button id="resetFilter" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <!--begin::customer-->
    <div class="card card-custom mt-5">

      <div class="card-body">

        <div class="col-md-12 mb-5 p-0">
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row">
              <div :id="'summaryTableDetails'+filters.group_by+'Title'" class="col-12 mt-10">
                <h4 class="text-center">{{ $t('aged_debtors.aged_debtors') }}</h4>
              </div>

<!--              <div v-if="dataChartList" class="col-12 mt-10">-->
<!--                <BarChart :currency-name="currency_name" :data-list="dataChartList"></BarChart>-->
<!--              </div>-->

              <div class="col-12 mt-10 d-flex justify-content-end">
                <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
                  <!--                                    <button class="dropdown-item" @click="convertTableToExcelReport">-->
                  <!--                                        <i class="la la-file-excel"></i>{{$t('excel')}}-->
                  <!--                                    </button>-->
<!--                  <download-excel-->
<!--                      :fetch="getDataList"-->
<!--                      :fields="json_fields"-->
<!--                      :name="$t('aged_debtors.aged_debtors')+'.xls'"-->
<!--                      class="dropdown-item">-->
<!--                    <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                  </download-excel>-->
                  <button class="dropdown-item" @click="convertTableToExcelReport('summaryTableDetails'+filters.group_by)">
                    <span><i class="fa fa-file-excel"></i> <span>{{ $t('excel') }}</span></span>
                  </button>
                  <button class="dropdown-item" @click="printData('summaryTableDetails'+filters.group_by)">
                    <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                  </button>
                  <button class="dropdown-item" @click="printPDFData('summaryTableDetails'+filters.group_by, 'pdf')">
                    <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                  </button>
                </b-dropdown>
              </div>
              <div class="col-12 mt-10">
                <div class="table-responsive">
                  <table :id="'summaryTableDetails'+filters.group_by" class="table table-bordered">
                    <thead>
                    <tr id="myRow"  style="text-align: center;">
                      <th>#</th>
                      <th>{{$t('client_id')}}</th>
                      <th>{{ $t('aged_debtors.client') }}</th>
                      <th>{{ $t('aged_debtors.not_overdue') }}</th>
                      <th>{{ $t('aged_debtors.f_0_to_30_day') }}</th>
                      <th>{{ $t('aged_debtors.f_31_to_60_day') }} </th>
                      <th>{{ $t('aged_debtors.f_61_to_90_day') }}</th>
                      <th>{{ $t('aged_debtors.f_91_to_120_day') }}</th>
                      <th>{{ $t('aged_debtors.more_than_121_day') }}</th>
                      <th>{{ $t('aged_debtors.sum_over_due') }}</th>
                      <th>{{ $t('aged_debtors.total') }}</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(row2, index2) in data" :key="'c'+getRandom()+index2">
                      <td>{{index2 + 1}}</td>
                      <td>{{ row2.client_id }}</td>
                      <td style="display: block;word-break: break-word;">{{ row2.client }}</td>
                      <td>{{ row2.not_overdue }}</td>
                      <td>{{ row2.f_0_to_30_day }}</td>
                      <td>{{ row2.f_31_to_60_day }}</td>
                      <td>{{ row2.f_61_to_90_day }}</td>
                      <td>{{ row2.f_91_to_120_day }}</td>
                      <td>{{ row2.more_than_121_day }}</td>
                      <td>{{ row2.sum_over_due }}</td>
                      <td>{{ row2.total }}</td>
                    </tr>

                    </tbody>
                    <tfoot>
                    <tr v-if="dataTotal">
                      <td colspan="3">{{ $t('total') }}</td>
                      <th style="text-align: center;">{{ dataTotal.sum_not_overdue }}</th>
                      <th style="text-align: center;">{{ dataTotal.sum_f_0_to_30_day }}</th>
                      <th style="text-align: center;">{{ dataTotal.sum_f_31_to_60_day }}</th>
                      <th style="text-align: center;">{{ dataTotal.sum_f_61_to_90_day }}</th>
                      <th style="text-align: center;">{{ dataTotal.sum_f_91_to_120_day }}</th>
                      <th style="text-align: center;">{{ dataTotal.sum_more_than_121_day }}</th>
                      <th style="text-align: center;">{{ dataTotal.sum_over_due }}</th>
                      <th style="text-align: center;">{{ dataTotal.sum_total }}</th>
                    </tr>
                    </tfoot>
                  </table>

                </div>

              </div>
            </div>
          </div>

        </div>
        <!-- End Filter -->
        <!--                <div class="col-12 text-center" v-if="page">-->
        <!--                    <button class="btn btn-warning" @click="loadMore">-->
        <!--                        <p class="mb-0"><b-icon icon="arrow-counterclockwise" font-scale="1"></b-icon> {{$t('load_more')}}</p>-->
        <!--                    </button>-->
        <!--                </div>-->
      </div>
    </div>
    <div id="selectorExport" class="selector-export"></div>
    <div class="export-container">
      <div id="sectionForExportHeader" class="section-for-export">
        <report-main-header :print-type="'AgedDebtors'"></report-main-header>
      </div>
    </div>
    <!--end::customer-->
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
// import TableToExcel from "@linways/table-to-excel";
import BarChart from "../components/BarChart";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import TableToExcel from "@linways/table-to-excel";


export default {
  name: "index-customer-sales-report",
  components: {ReportMainHeader, 'BarChart': BarChart},
  data() {
    return {
      mainRoute: '/reports/customer-debtors-report/data-list',
      mainRouteChart: '/reports/customer-debtors-report/data-list-chart',
      mainRouteDependency: 'base/dependency',

      data: [],
      dataTotal: null,
      dataChartList: null,

      customers: [],

      filters: {
        customer_id: null,
        from_date: null,
        to_date: null,
        period: null,
        f_time_period: 0,
        branches_ids: [],
      },
      branches:[],
      branches_ids: [],
      inner_period: null,
      currency_name: null,
      customer: null,
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,
    }
  },
  computed: {
    json_fields: function () {
      let fields = {};
      fields[this.$t('aged_debtors.client')] = 'client';
      fields[this.$t('aged_debtors.not_overdue') + ` (${this.currency_name})`] = 'not_overdue';
      fields[this.$t('aged_debtors.f_0_to_30_day') + ` (${this.currency_name})`] = 'f_0_to_30_day';
      fields[this.$t('aged_debtors.f_31_to_60_day') + ` (${this.currency_name})`] = 'f_31_to_60_day';
      fields[this.$t('aged_debtors.f_61_to_90_day') + ` (${this.currency_name})`] = 'f_61_to_90_day';
      fields[this.$t('aged_debtors.f_91_to_120_day') + ` (${this.currency_name})`] = 'f_91_to_120_day';
      fields[this.$t('aged_debtors.more_than_121_day') + ` (${this.currency_name})`] = 'more_than_121_day';
      fields[this.$t('aged_debtors.sum_over_due') + ` (${this.currency_name})`] = 'sum_over_due';
      fields[this.$t('aged_debtors.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },

  },
  watch: {
    customer: function (val) {
      if (val) {
        this.filters.customer_id = val.id;
      } else {
        this.filters.customer_id = null;
      }
    },

  },
  methods: {
    ...cssStypeForPrintReport,
    // convertTableToExcelReport() {
    //     TableToExcel.convert(document.getElementById('summaryTableDetails'+this.filters.group_by), {
    //         name: this.$t('aged_debtors.aged_debtors') + '.xlsx',
    //         sheet: {
    //             name: 'Sheet 1'
    //         }
    //     });
    // },
    getIds(related_with) {

      switch (related_with) {
        // case 'customers':
        //   this.filters.customers_ids = this.customers_ids.map((row) => row.id);
        //   this.filters.is_all_customers = 0;
        //   break;
        case 'branches':
          this.filters.branches_ids = this.branches_ids.map((row) => row.id);
          // this.filters.is_all_branches = 0;
          break;
        // case 'users':
        //   this.filters.user_id = this.user.id;
        //   break;
        // case 'status':
        //   this.filters.status_ids = this.status_ids.map((row) => row.id);
        //   break;
        // case 'inventories':
        //   this.filters.inventories_ids = this.inventories_ids.map((row) => row.id);
        //   break;
      }


    },
    changeIntervalType(action_type) {
      if (this.filters.period == action_type) {
        this.filters.period = null;
        this.filters.f_time_period = 0;
      } else {
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }
    },
    getDataList() {
      this.setReportLog('excel', 'aged debtors');
      let _data = [...this.data];
      _data.push({
        client: this.$t('total'),
        not_overdue: this.dataTotal.sum_not_overdue,
        f_0_to_30_day: this.dataTotal.sum_f_0_to_30_day,
        f_31_to_60_day: this.dataTotal.sum_f_31_to_60_day,
        f_61_to_90_day: this.dataTotal.sum_f_61_to_90_day,
        f_91_to_120_day: this.dataTotal.sum_f_91_to_120_day,
        more_than_121_day: this.dataTotal.sum_more_than_121_day,
        sum_over_due: this.dataTotal.sum_over_due,
        total: this.dataTotal.sum_total,
      });
      return _data;
    },
    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    async getAuthUser() {
      await ApiService.get(`/get_auth`).then((response) => {
        this.user = response.data.data;
        this.currency_name = response.data.data.currency_name;
        if (this.user) {
          this.user_name = this.user.name;
        }
      });
    },

    // selectFilter() {
    //     this.getData();
    // },

    doFilter() {
      this.page = 1;
      // this.getDataChart();
      this.getData();
    },
    resetFilter() {
      this.customer = null;
      this.branches_ids = [];
      this.filters.branches_ids = [];
      this.filters.customer_id = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.period = null;

      // this.getDataChart();
      this.getData();

    },
    dataReport() {
      return this.data;
    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getData();
      // this.getDataChart();
    },
    getData() {
      ApiService.get(this.mainRoute, {
        params: {
          ...this.filters,
          // page: (this.page ? this.page : 1)
        }
      }).then((response) => {
        this.data = response.data.data.data;
        this.dataTotal = response.data.data.total;
      }).then().catch((error) => {
        this.$errorAlert(error)
      });
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    // getDataChart() {
    //   ApiService.get(this.mainRoute, {
    //     params: {
    //       ...this.filters,
    //       page: (this.page ? this.page : 1),
    //       data_type: 'chart'
    //     }
    //   }).then((response) => {
    //     // ApiService.get(this.mainRoute+'?data_type=chart', {params: {...this.filters}}).then((response) => {
    //     // this.dataChartList = response.data;
    //     this.dataChartList = {
    //       labels: [
    //         this.$t('aged_debtors.not_overdue'),
    //         this.$t('aged_debtors.f_0_to_30_day'),
    //         this.$t('aged_debtors.f_31_to_60_day'),
    //         this.$t('aged_debtors.f_61_to_90_day'),
    //         this.$t('aged_debtors.f_91_to_120_day'),
    //         this.$t('aged_debtors.sum_over_due'),
    //       ],
    //       total: response.data.data.total
    //     };
    //
    //   });
    // },


    getCustomers(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    printData(tableId) {
      this.setReportLog('pdf', 'Aged Debtors');
      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },
    convertTableToExcelReport(_tableId) {
      TableToExcel.convert(document.getElementById(_tableId), {
        name: this.$t('MENU.suppliers_balance_reports')+'.xlsx',
        sheet: {
          name: 'Sheet 1'
        }
      });
    },
    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'aged debtors');
      exportPDFInnerAction(tableId, type, this.$t('aged_debtors.aged_debtors'), this.$t('aged_debtors.aged_debtors'));
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.clients_reports"),
      route: '/clients/reports'
    }, {title: this.$t("MENU.aged_debtors")}]);

    this.getAuthUser();
  },
  created() {
    this.getBranches();
  }
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000;
  width: 0.0001px;
  height: 0.0001px;
  overflow: auto;
}
</style>
